<template>
  <div class="d-flex flex-column flex-row-fluid wrapper mr-lg-10 mr-sm-0">
    <div class="container-fluid d-flex justify-content-end align-items-center">
      <!--begin::Logo-->
      <div class="symbol-label bg-transparent">
        <div
          v-if="companySelected.logo"
          class="d-flex flex-column justify-content-center"
        >
          <div class="symbol-60 symbol-xl-90">
            <div class="symbol-label">
              <img
                v-if="companySelected.logo"
                class="max-h-100px max-w-200px"
                :src="`${$baseUrl}${companySelected.logo}`"
              />
            </div>
          </div>
          <h4>{{ companySelected.name }}</h4>
        </div>
        <div v-else class="d-flex flex-column justify-content-center">
          <h4>{{ companySelected.name }}</h4>
        </div>
      </div>
      <!--end::Logo-->
    </div>
    <div class="content d-flex flex-column flex-column-fluid p-0">
      <div class="d-flex flex-column-fluid">
        <div class="container-fluid">
          <router-view />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/controller/Footer.component";
import { mapGetters } from "vuex";
import { getMimetype } from "@/utils/helpers";

export default {
  name: "DefaultLayout",
  components: {
    Footer
  },
  computed: {
    ...mapGetters("Controller", ["companySelected"])
  },
  methods: {
    callGetMimetype(base64) {
      return getMimetype(base64);
    }
  }
};
</script>
